import { ControllerParams, CreateControllerFn } from '@wix/yoshi-flow-editor';
import { getGroupFromLocation } from './controllers/helpers';
import { MainController } from './controllers/main/MainController';

const createController: CreateControllerFn = async (
  controllerContext: ControllerParams,
) => {
  console.log('Group Page controller');
  // For more info about controller structure,
  // check the docs: https://bo.wix.com/pages/yoshi/docs/editor-flow/structure-api/component#controller
  const controllerConfig = controllerContext.controllerConfig;
  const group = await getGroupFromLocation(controllerConfig as any);

  return new MainController(controllerContext, group) as any; // TODO: [YO] fix type
};

export default createController;
