import { Controller } from '../Controller';
import {
  EVENTS_SCOPE,
  IEventsControllerActions,
  IEventsControllerState,
} from './EventsControllerProps';
import { EventsApi } from '@wix/social-groups-api/dist/src/services/eventsApi/EventsApi';
import { ControllerParams } from '@wix/yoshi-flow-editor';
import { EventStatus } from '@wix/ambassador-wix-events-web/src/types';

export class EventsController
  extends Controller<IEventsControllerState>
  implements IEventsControllerActions
{
  private eventsApi!: EventsApi;

  protected state: IEventsControllerState = {
    events: null as any,
    limit: 100,
    offset: 0,
    total: 0,
  };

  constructor(controllerContext: ControllerParams, groupId: string) {
    super(controllerContext, groupId);

    this.setApi();

    this.onUserLogin(async () => {
      this.setApi();
    });
  }

  private setApi() {
    this.eventsApi = new EventsApi(this.getSiteToken()!, this.getApiBaseUrl());
  }

  setState(props: Partial<IEventsControllerState>) {
    this.state = { ...this.state, ...props };
    this.controllerConfig.setProps({
      [EVENTS_SCOPE]: {
        ...this.state,
        fetch: this.fetch.bind(this),
        queryIds: this.queryIds,
      },
    });

    return this.state;
  }

  pageReady(): Promise<void> {
    return Promise.resolve();
  }

  async fetch() {
    try {
      const groupId = await this.getGroupId();
      const { events, pagingMetadata } = await this.eventsApi.getGroupEvents(
        groupId!,
      );
      const { count, offset, total } = pagingMetadata || {};
      this.setState({
        events,
        limit: count,
        offset,
        total,
        error: null as any,
      });
    } catch (error) {
      this.setState({ error: error.response.message });
    }
  }

  async getInitialProps() {
    return this.setState({});
  }

  queryIds = async (ids: string[]) => {
    try {
      const { events, facets, pagingMetadata } =
        await this.eventsApi.queryEvents({
          query: {
            filter: {
              eventId: {
                $hasSome: ids,
              },
            },
          },
        });
      const { total } = pagingMetadata!;
      this.setState({
        events,
        total,
        error: null as any,
        statusCounts: facets?.status?.counts as {
          [key in EventStatus]: number;
        },
      });
    } catch (e) {
      this.setState({ error: e.response.message });
    }
  };
}
