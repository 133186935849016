import {
  IControllerConfig,
  IPublicData,
} from '@wix/native-components-infra/dist/src/types/types';

import Api, {
  answerToV2,
  AppToastTypes,
  Group,
  hasAdminRole,
  hasSiteAdminRole,
  isJoined,
  MediaApi,
  VideoMetadataApi,
  groupToV2,
} from '@wix/social-groups-api';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { Controller } from '../Controller';
import { GroupControllerProps } from './GroupControllerProps';
import { PubSubObserver } from '../pubSub/PubSubObserver';
import { PubSubEventTypes } from '../pubSub/PubSubEventTypes';
import { MembershipAnswersWriteApi } from '@wix/social-groups-api/dist/src/model/MembershipAnswers/MembershipAnswersApi';
import { MembershipQuestionsApi } from '@wix/social-groups-api/dist/src/model/MembershipQuestions/MembershipQuestionsApi';
import {
  AppToastsController,
  getGroupUrlSegments,
  Tab,
} from '../../../../common/controllers';
import { Button } from '../../types/button';
import { IGroupActions } from '../../types/IGroupActions';
import { UpdateProgress } from '../../../../common/ContentEditor/UpdateProgress';
import { getRCApiBaseUrl } from '../../../../common/utils/baseUrl';
import { ConsoleLogger } from '../../../../common/loggers';
import { createEventHandler } from '@wix/yoshi-flow-editor/tpa-settings';
import { ControllerParams } from '@wix/yoshi-flow-editor';
import { GroupsApi } from '../../../../common/api/v2/GroupsApi';
import {
  errorEventFromAmbassador,
  ErrorOrigin,
  IErrorEvent,
} from '../errorHandler/IErrorEvent';
import {
  MembershipQuestionAnswer,
  Group as GroupV2,
} from '@wix/ambassador-social-groups-web/types';
import { restrictedByAdmin } from '@wix/social-groups-api/dist/src/model/Group/accessRestriction/v2';

enum MemberActionType {
  LEAVE = 'leave',
  JOIN = 'join',
  WITHDRAW = 'withdraw',
}

export enum SettingsEventsTypes {
  activeTab = 'activeTabChanged',
  activeButton = 'activeButton',
}

export interface SettingsEvents {
  [SettingsEventsTypes.activeTab]: Tab;
  [SettingsEventsTypes.activeButton]: Button;
}

export class GroupController
  extends Controller<GroupControllerProps>
  implements PubSubObserver, MembershipAnswersWriteApi, MembershipQuestionsApi
{
  private apiClient!: Api;
  private groupModel!: Group;
  private mediaApi!: MediaApi;
  private videoMetadataApi!: VideoMetadataApi;
  private pendingAction!: MemberActionType;
  private readonly subscriptions: Map<number, PubSubEventTypes> = new Map();
  private handler!: any;
  private apiV2!: GroupsApi;

  constructor(
    controllerContext: ControllerParams,
    private group: ApiTypes.v1.GroupResponse,
  ) {
    super(controllerContext, group.groupId!);
    this.initExternalServices(this.getSiteToken()!, group.groupId!);
    this.getLocation().onChange(this.handleLocationChange);
    this.onUserLogin(async () => {
      const id = await this.getGroupId();
      this.initExternalServices(this.getSiteToken()!, id!);
      await this.setGroup();
      return this.executePendingAction();
    });
    this.setSubscriptions();
    this.subscribeToPublicData(this.controllerConfig.config.publicData);
    this.ensureGroupNameInPath();
  }

  pageReady = async () => {
    this.setState({
      changeTab: this.changeTab, // TODO: why twice?
      actions: this.getActions(),
      promptLogin: this.promptLogin,
    });
    if (this.shouldTriggerJoin()) {
      this.joinGroup().catch((e) => {
        console.log('FAILED to join group with join trigger');
      });
    }
    return;
  };

  ensureGroupNameInPath = async () => {
    const location = this.getLocation();
    const siteApis = this.getSiteApis();

    const segments = await getGroupUrlSegments(location, siteApis);

    this.maybeRedirect(this.group.slug!, segments.groupId);
  };

  updateConfig($w: any, config: any) {
    this.notifyPublicDataChanged(config);
  }

  promptLogin = () => super.promptLogin({ modal: true });

  private readonly getActions = (): IGroupActions => ({
    changeTab: this.changeTab, // TODO: why twice?
    getExternalVideoMetadata: this.getVideoMetadata,
    uploadFiles: this.uploadFiles,
    updateGroup: this.updateGroup,
    configureApps: this.configureApps,
    setEditMode: this.setEditMode,
    deleteGroup: this.deleteGroup,
    goToGroupList: this.goToGroupList,
    joinGroup: this.joinGroup, // TODO: can we move it to MembersController?
    withdrawJoinRequest: this.withdrawJoinRequest, // TODO: can we move it to MembersController?
    leaveGroup: this.leaveGroup, // TODO: can we move it to MembersController?
    inviteMembersByEmail: this.inviteMembersByEmail, // TODO: can we move it to MembersController?
    fetchGroupRules: this.fetchGroupRules.bind(this),
    submitAnswers: this.submitAnswers,
    rejectAnswers: this.rejectAnswers,
    saveMembershipQuestions: this.saveMembershipQuestions,
    getMembershipQuestions: this.getMembershipQuestions,
    resetUpdatesCounter: this.resetUpdatesCounter
  });

  async getInitialProps(): Promise<Partial<GroupControllerProps>> {
    const { activeTab, feedItemId } = await this.getUrlSegments();

    const updateProgress = this.shouldTriggerJoin()
      ? UpdateProgress.UPDATING
      : UpdateProgress.STALE;
    const initialProps: Partial<GroupControllerProps> = {
      locale: this.getLocale(),
      cssBaseUrl: this.getCssBaseUrl(),
      activeTab,
      activeButton: Button.PRIMARY,
      feedItemId,
      uploadedRegistry: [],
      externalVideosMetadataRegistry: [],
      isLoggedIn: this.isUserLoggedIn(),
      updateProgress,
      group: this.group,
      loading: false,
    };
    try {
      initialProps.apps = await this.groupModel.getApps();
    } catch (e) {
      console.log('GroupController.getInitialProps: FAILED to fetch GroupApps');
      this.errorLogger.log(e);
    }
    this.state = initialProps;
    return Promise.resolve(initialProps);
  }

  private readonly uploadFiles = async (filename: string, file: File) => {
    this.setState({ updateProgress: UpdateProgress.UPDATING });
    const uploadFile = { filename, uploadResponse: null, error: null } as any;
    try {
      if (file) {
        const { data } = await this.mediaApi.uploadFile(file);
        uploadFile.uploadResponse = data;
      } else {
        uploadFile.uploadResponse = [null];
      }
    } catch (e) {
      uploadFile.error = (e && e.response && e.response.data) || e;
      this.errorLogger.log(e);
    } finally {
      this.setState({
        uploadedRegistry: [uploadFile],
        updateProgress: UpdateProgress.STALE,
      });
    }
  };

  private readonly getVideoMetadata = async (videoUrl: string) => {
    let updatedRegistry = [...this.state.externalVideosMetadataRegistry!];
    const prevResIndex = updatedRegistry.findIndex((metadata) => {
      return metadata.videoUrl === videoUrl;
    });

    this.setState({ updateProgress: UpdateProgress.UPDATING });

    // does not have previous result in registry for the videoUrl
    if (prevResIndex === -1) {
      const metadataResponse = { videoUrl, metadata: null, error: null } as any;

      try {
        const { data } = await this.videoMetadataApi.getVideoMetadata(videoUrl);
        metadataResponse.metadata = data;
      } catch (e) {
        metadataResponse.error = e.response.data;
        this.errorLogger.log(e);
      } finally {
        updatedRegistry = updatedRegistry.concat([metadataResponse]);
      }
    }

    this.setState({
      externalVideosMetadataRegistry: updatedRegistry,
      updateProgress: UpdateProgress.STALE,
    });
  };

  setState(props: Partial<GroupControllerProps>) {
    this.state = { ...this.state, ...props };
    this.controllerConfig.setProps(props);
  }

  async fetchGroupRules() {
    const rules = await this.groupModel.getRules();

    this.setState({ rules });
  }

  withdrawJoinRequest = () => {
    this.pendingAction = MemberActionType.WITHDRAW;
    return this.changeMembership();
  };

  leaveGroup = () => {
    this.pendingAction = MemberActionType.LEAVE;
    return this.changeMembership();
  };

  joinGroup = () => {
    this.pendingAction = MemberActionType.JOIN;
    return this.changeMembership();
  };

  handleLocationChange = ({ path }: { path: string[] }) => {
    this.getUrlSegments()
      .then(({ activeTab, feedItemId }) => {
        this.setState({
          activeTab,
          feedItemId,
        });
      })
      .catch((e) => {
        console.log('[GroupController.handleLocationChange] Failed');
        this.errorLogger.log(e);
      });
  };

  changeTab = async (tab: Tab) => {
    if (this.isEditorMode()) {
      return this.setState({ activeTab: tab });
    }
    const location = this.getLocation();

    try {
      const groupUrl = await this.getGroupUrl({
        groupId: this.group.slug!,
        tabName: tab,
      });
      const _url = groupUrl.replace(location.baseUrl, ''); // absolute urls reloads page
      location.to!(`${_url}#preventScrollToTop`);
    } catch (e) {
      console.log('[GroupController.changeTab] Failed');
      this.errorLogger.log(e);
    }
  };

  changeActiveButton = (button: Button) => {
    this.setState({ activeButton: button });
  };

  private getCssBaseUrl() {
    const { staticsGroupBaseUrl, staticsBaseUrl } =
      this.controllerConfig.appParams.baseUrls;
    return staticsGroupBaseUrl || staticsBaseUrl;
  }

  private initExternalServices(instance: string, groupId: string) {
    this.apiClient = new Api(instance, this.getApiBaseUrl());
    this.mediaApi = new MediaApi(this.apiClient);
    this.apiV2 = new GroupsApi(instance);
    const location = this.getLocation();
    this.videoMetadataApi = new VideoMetadataApi(
      instance,
      getRCApiBaseUrl(location.baseUrl),
    );
    this.groupModel = new Group(
      groupId,
      this.apiClient,
      this.controllerConfig.platformAPIs,
    );
  }

  onBeforeUnLoad() {
    this.removeSubscriptions();
  }

  removeSubscriptions() {
    // https://wix.slack.com/archives/CAKBA7TDH/p1601480311035300
    // TODO: remove after fixed on TB side

    try {
      const storage = this.controllerConfig.platformAPIs.storage.session;
      const key = 'GroupController.setSubscriptions';
      const subscr = JSON.parse(storage.getItem(key)!);
      if (!subscr) {
        return;
      }
      for (const [id, event] of Object.entries(subscr) as any) {
        this.controllerConfig.platformAPIs.pubSub.unsubscribe(event, id);
      }
      storage.removeItem(key);
    } catch (e) {
      console.log('Error in GroupController.removeSubscriptions');
    }
    //
    try {
      this.subscriptions.forEach((event, subscrId) => {
        this.controllerConfig.platformAPIs.pubSub.unsubscribe(event, subscrId);
      });
    } catch (e) {
      ConsoleLogger.log(e);
    } finally {
      this.subscriptions.clear();
    }
  }

  setSubscriptions() {
    try {
      this.subscriptions.set(
        this.controllerConfig.platformAPIs.pubSub.subscribe(
          PubSubEventTypes.UPDATE_GROUP_EVENT,
          () => {
            return this.setGroup();
          },
          false,
        ) || Date.now(),
        PubSubEventTypes.UPDATE_GROUP_EVENT,
      );

      this.subscriptions.set(
        this.controllerConfig.platformAPIs.pubSub.subscribe(
          PubSubEventTypes.WILL_UPDATE_GROUP_EVENT,
          () => {
            this.setState({ updateProgress: UpdateProgress.STARTED });
          },
          false,
        ) || Date.now() + 1,
        PubSubEventTypes.WILL_UPDATE_GROUP_EVENT,
      );
    } catch (e) {
      ConsoleLogger.log(e);
      this.errorLogger.log(e);
    }
  }

  subscribeToPublicData(publicData: IPublicData) {
    const data = publicData.COMPONENT || {};
    const handler = createEventHandler<SettingsEvents>(data);

    handler.on(SettingsEventsTypes.activeTab, (value: Tab) => {
      this.changeTab(value);
    });
    handler.on(SettingsEventsTypes.activeButton, (value: Button) => {
      this.changeActiveButton(value);
    });

    handler.onReset(() => {
      this.changeTab(Tab.DISCUSSION);
      this.changeActiveButton(Button.PRIMARY);
    });

    this.handler = handler;
  }

  notifyPublicDataChanged(config: IControllerConfig) {
    this.handler.notify(config.publicData.COMPONENT || {});
  }

  private readonly setGroup = async () => {
    try {
      const group = await this.groupModel.fetch();
      this.group = group;
      this.publishDidUpdateGroup(group);
      this.setState({
        group,
        updateProgress: UpdateProgress.STALE,
        questions: null as any,
      });
    } catch (e) {
      this.errorLogger.log(e);
    }
  };

  private publishUIHandledError(errorEvent: IErrorEvent) {
    try {
      this.controllerConfig.platformAPIs.pubSub.publish(
        PubSubEventTypes.CONTROLLER_ERROR,
        JSON.stringify(errorEvent),
        false,
      );
    } catch (e) {
      ConsoleLogger.log(e);
      this.errorLogger.log(e);
    }
  }

  private publishDidUpdateGroup(group: ApiTypes.v1.GroupResponse) {
    try {
      this.controllerConfig.platformAPIs.pubSub.publish(
        PubSubEventTypes.DID_UPDATE_GROUP_EVENT,
        JSON.stringify(group),
        false,
      );
    } catch (e) {
      ConsoleLogger.log(e);
      this.errorLogger.log(e);
    }
  }

  private publishMembersUpdate(groupId: string) {
    try {
      this.controllerConfig.platformAPIs.pubSub.publish(
        PubSubEventTypes.UPDATE_MEMBERS_EVENT,
        groupId,
        false,
      );
      this.controllerConfig.platformAPIs.pubSub.publish(
        PubSubEventTypes.UPDATE_CONTEXT_TOKENS_EVENT,
        groupId,
        false,
      );
    } catch (e) {
      ConsoleLogger.log(e);
      this.errorLogger.log(e);
    }
  }

  private readonly changeMembership = () => {
    if (this.isUserLoggedIn()) {
      return this.executePendingAction();
    }
    return this.promptLogin();
  };

  private async executePendingAction() {
    if (!this.pendingAction) {
      return;
    }

    try {
      this.setState({ updateProgress: UpdateProgress.UPDATING });
      switch (this.pendingAction) {
        case MemberActionType.LEAVE:
          await this.groupModel.leaveGroup(this.group);
          break;
        case MemberActionType.JOIN:
          return this.maybeJoin();
        case MemberActionType.WITHDRAW:
          await this.groupModel.withdrawJoinRequest(this.group);
          break;
        default:
          throw new Error('Unknown membership action');
      }
      await this.update();
    } catch (e) {
      console.log('Failed to execute change membership action');
      this.errorLogger.log(e);
      this.setState({ updateProgress: UpdateProgress.STALE });
    } finally {
      this.pendingAction = null as any;
    }
  }

  private async update() {
    await this.setGroup();
    this.publishMembersUpdate(this.group.groupId!);
  }

  private readonly setEditMode = (updateProgress: UpdateProgress) => {
    this.setState({ updateProgress });
  };

  private readonly updateGroup = async (
    paths: string[],
    details?: ApiTypes.v1.GroupDetails,
    settings?: ApiTypes.v1.GroupSettings,
  ) => {
    this.setState({ updateProgress: UpdateProgress.UPDATING });
    this.groupModel.updateGroup(paths, details, settings);
    const group = await this.groupModel.update();
    this.onGroupUpdated(this.group, group);
  };

  private onGroupUpdated(
    group: ApiTypes.v1.GroupResponse,
    updatedGroup: ApiTypes.v1.GroupResponse,
  ) {
    this.group = updatedGroup;
    this.setState({ group: this.group, updateProgress: UpdateProgress.STALE });
    this.maybeRedirect(updatedGroup.slug!, group.slug!).catch((e) => {
      console.log('[GroupController.onGroupUpdated] Failed');
      this.errorLogger.log(e);
    });
  }

  private async maybeRedirect(slug: string, prevSlug: string) {
    // slug changed
    try {
      if (slug && prevSlug !== slug) {
        const location = this.getLocation();
        const url = await this.getGroupUrl({
          groupId: slug,
          tabName: Tab.DISCUSSION,
        });
        location.to!(url);
      }
    } catch (e) {
      console.log('[GroupController.maybeRedirect] Error');
      this.errorLogger.log(e);
    }
  }

  private async getGroupApps() {
    const apps = await this.groupModel.getApps();
    this.setState({ apps });
  }

  private readonly configureApps = async (apps: ApiTypes.v1.GroupApp[]) => {
    this.setState({ updateProgress: UpdateProgress.UPDATING });
    const updatedApps = await this.groupModel.configureApps({ apps });
    this.setState({ updateProgress: UpdateProgress.STALE, apps: updatedApps });
  };

  private readonly inviteMembersByEmail = (emails: string[]) => {
    return this.groupModel.inviteMembersByEmail(emails);
  };

  private readonly deleteGroup = async () => {
    try {
      const groupName = this.groupModel.getTitle();
      await this.groupModel.delete();
      AppToastsController.publish(this.controllerConfig.platformAPIs.pubSub, {
        type: AppToastTypes.GROUP_DELETED,
        options: {
          'group-name': groupName,
        },
      });
      const location = this.getLocation();
      location.to!('/groups'); // TODO: [YO] Groups url can be different!!
    } catch (e) {
      console.error('Delete Group failed');
      this.errorLogger.log(e);
    }
  };

  private async maybeJoin() {
    // when users clicks join before they are logged in
    if (isJoined(this.group as any)) {
      this.pendingAction = null as any;
      this.setState({ updateProgress: UpdateProgress.STALE });
      return;
    }
    // !admin && questions?
    // TODO: TD refactor
    if (
      !hasSiteAdminRole(this.group.roles!) &&
      !hasAdminRole(this.group.roles!)
    ) {
      const questions = await this.getQuestions();
      if (questions.length) {
        this.setState({ questions, updateProgress: UpdateProgress.STALE });
        return;
      }
    }
    // join
    return this.join();
  }

  private async join(answers?: ApiTypes.v1.QuestionAnswer[]) {
    if (this.experimentEnabled('specs.groups.NewJoinFlow')) {
      try {
        await this.joinV2(
          groupToV2(this.group),
          answers && answers.map(answerToV2),
        );
      } catch (e) {
        const errEv = errorEventFromAmbassador(e, ErrorOrigin.JoinGroup);
        this.publishUIHandledError(errEv);
      }
    } else {
      await this.groupModel.joinGroup(this.group, answers);
    }
    await this.update();
    this.pendingAction = null as any;
  }

  private async getQuestions(): Promise<ApiTypes.v1.Question[]> {
    try {
      // TODO: cache
      const {
        data: { questions },
      } = await this.apiClient.members.getMembershipQuestions(
        this.group.groupId!,
      );

      return questions!;
    } catch (e) {
      console.log(
        'GroupController.getQuestions: Error getting membership questions',
      );
      this.errorLogger.log(e);
    }
    return [];
  }

  submitAnswers = async (
    group: ApiTypes.v1.GroupResponse,
    answers: ApiTypes.v1.QuestionAnswer[],
  ) => {
    if (!answers) {
      return this.rejectAnswers();
    }
    await this.join(answers);
  };
  rejectAnswers = () => {
    this.pendingAction = null as any;
    this.setState({ questions: null } as any);
  };
  getMembershipQuestions = async (groupId: string) => {
    try {
      const questions = await this.getQuestions();
      this.setState({ questions });
    } catch (e) {
      console.error(e);
      this.errorLogger.log(e);
    }
    return null as any;
  };

  saveMembershipQuestions = async (
    groupId: string,
    mqs: ApiTypes.v1.Question[],
  ) => {
    try {
      const {
        data: { questions },
      } = (await this.apiClient.members.saveMembershipQuestions({
        groupId,
        questions: mqs,
      })) as any;
      this.setState({ questions });
    } catch (e) {
      console.log('Error in MembersController.saveMembershipQuestions');
      this.errorLogger.log(e);
    }
    return null as any;
  };

  resetUpdatesCounter = () => {
    this.apiV2.getGroupUpdatesService().resetUpdatesCounter({
      groupId: this.group.groupId!
    });
  };

  private shouldTriggerJoin() {
    const { query } = this.getLocation();
    return (
      this.experimentEnabled('specs.groups.TriggerJoinGroup') &&
      query.invite === 'true'
    );
  }

  private async joinV2(
    group: GroupV2,
    answers: MembershipQuestionAnswer[] | undefined,
  ) {
    const { id, accessRestriction } = group;
    const joinRequest = {
      groupId: id,
      membershipQuestionAnswers: answers,
    };
    if (restrictedByAdmin(accessRestriction!)) {
      return this.apiV2.getJoinService().submitJoinGroupRequest(joinRequest);
    }
    return this.apiV2.getMembersService().joinGroup(joinRequest);
  }
}
