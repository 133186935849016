import { SocialGroupsWeb } from '@wix/ambassador-social-groups-web/http';

type SGW = ReturnType<typeof SocialGroupsWeb>;
type GroupsService = ReturnType<ReturnType<SGW['GroupsService']>>;
type JoinService = ReturnType<ReturnType<SGW['JoinGroupRequestsService']>>;
type GroupMembersService = ReturnType<ReturnType<SGW['GroupMembersService']>>;
type GroupUpdatesService = ReturnType<ReturnType<SGW['GroupUpdatesService']>>;

export const GROUPS_API_URL = '/_api/social-groups';

export class GroupsApi {
  private readonly groupsService: GroupsService;
  private readonly headers: any;
  private readonly joinService: JoinService;
  private memberService: GroupMembersService;
  private groupUpdatesService: GroupUpdatesService;

  constructor(token: string) {
    this.headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    };
    const socialGroupsWeb = SocialGroupsWeb(GROUPS_API_URL);
    this.groupsService = socialGroupsWeb.GroupsService()(this.headers);
    this.joinService = socialGroupsWeb.JoinGroupRequestsService()(this.headers);
    this.memberService = socialGroupsWeb.GroupMembersService()(this.headers);
    this.groupUpdatesService = socialGroupsWeb.GroupUpdatesService()(this.headers);
  }

  getGroupUpdatesService() {
    return this.groupUpdatesService;
  }

  getGroupsService() {
    return this.groupsService;
  }

  getJoinService() {
    return this.joinService;
  }

  getMembersService() {
    return this.memberService;
  }
}
