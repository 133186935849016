import { ISocialContentService } from '@wix/social-groups-api';
import {
  CommentsApiTypes,
  FeedApiTypes,
  ReactionsApiTypes,
} from '@wix/social-groups-api/dist/src/types';
import { action, flow, observable } from 'mobx';
import { list, object, primitive, serializable } from 'serializr';

import { SocialText } from '../../../types/feed';
import { Comment } from './Comment';
import { Attachment } from './Attachment';
import { Reactions } from './Reactions';
import { ConsoleLogger } from '../../../../../common/loggers/ConsoleLogger';
import {
  FeedItemActivityType,
  IFeedItem,
  IFeedItemActivity,
  IFeedItemActivityData,
  IFeedItemComments,
  IFeedItemEntity,
  IFeedItemPin,
  IFeedItemRequester,
} from '../../../types/IFeedItem';

class FeedItemActivityData implements IFeedItemActivityData {
  @serializable hasCoverBefore!: boolean;

  @serializable src!: string;

  @serializable content!: string;

  @serializable(list(primitive())) userIds: string[] = [];

  @serializable authorUserId!: string;

  @serializable activity!: string;
}

class FeedItemActivity implements IFeedItemActivity {
  @serializable activityType!: FeedItemActivityType;

  @serializable(object(FeedItemActivityData)) data!: FeedItemActivityData;
}

class FeedItemEntity implements IFeedItemEntity {
  @serializable(object(SocialText)) body!: SocialText;
  @serializable(list(primitive())) topics!: string[];
}

class FeedItemPin implements IFeedItemPin {
  @serializable since!: Date;

  @serializable pinnedBy!: string;
}

class FeedItemRequester implements IFeedItemRequester {
  @serializable subscribed!: boolean;
}

class FeedItemComments implements IFeedItemComments {
  @serializable(list(object(Comment)))
  @observable.shallow
  comments: Comment[] = [];

  @observable @serializable total: number = 0;

  @serializable contextToken: string;

  constructor(
    comments: FeedApiTypes.Comments = {
      total: 0,
      comments: [],
    },
  ) {
    this.total = comments.total!;
    this.comments = comments.comments!.map((comment) => new Comment(comment));
    this.contextToken = comments.contextToken!;
  }

  add(comment: CommentsApiTypes.Comment) {
    this.comments.push(new Comment(comment));
    this.total++;
  }

  remove(commentId: string) {
    this.comments = this.comments.filter((c) => c.commentId !== commentId);
    this.total = this.comments.length;
  }

  setTotal(total: number) {
    this.total = total;
  }

}

export class FeedItem implements IFeedItem {
  @serializable feedItemId: string;

  @serializable createdAt: Date;

  @serializable updatedAt: Date;

  @serializable createdBy: string;

  @serializable(object(FeedItemEntity)) @observable entity: FeedItemEntity;

  @serializable(object(FeedItemActivity)) activity: FeedItemActivity;

  @serializable(object(FeedItemComments)) comments: FeedItemComments;

  @serializable(object(Reactions)) @observable reactions: Reactions;

  @serializable(list(object(Attachment)))
  @observable.shallow
  attachments: Attachment[] = [];

  @serializable(object(FeedItemPin)) @observable pin: FeedItemPin;

  @serializable(object(FeedItemRequester))
  @observable
  requesterContext: FeedItemRequester;

  constructor(
    feedItem: FeedApiTypes.FeedItem,
    private repository: ISocialContentService,
  ) {
    this.feedItemId = feedItem.feedItemId!;
    this.createdAt = feedItem.createdAt!;
    this.createdBy = feedItem.createdBy!;

    this.entity = feedItem.entity as FeedItemEntity;
    this.activity = feedItem.activity as FeedItemActivity;

    this.comments = new FeedItemComments(feedItem.comments!);
    this.reactions = new Reactions(feedItem.reactions!);
    this.attachments = (
      (feedItem.entity && feedItem.entity.attachments) ||
      []
    ).map((attachment) => new Attachment(attachment));
    this.pin = feedItem.pin as FeedItemPin;
    this.requesterContext = feedItem.requesterContext as FeedItemRequester;
    this.updatedAt = feedItem.updatedAt!;
  }

  setRepository(repository: ISocialContentService) {
    this.repository = repository;
  }

  @action react = flow(function* (
    this: FeedItem,
    reaction: ReactionsApiTypes.Reaction,
  ) {
    try {
      const response: {
        data: ReactionsApiTypes.ItemReactionsSummary;
      } = yield this.repository.reactions.react({
        itemId: this.feedItemId,
        reaction,
      });

      this.reactions = new Reactions(response.data);
    } catch (error) {
      ConsoleLogger.log('FeedItem react error: ', error);
    }
  });

  @action unreact = flow(function* (this: FeedItem, reactionCode: string) {
    try {
      const response: {
        data: ReactionsApiTypes.ItemReactionsSummary;
      } = yield this.repository.reactions.unreact({
        itemId: this.feedItemId,
        reactionCode,
      });

      this.reactions = new Reactions(response.data);
    } catch (error) {
      ConsoleLogger.log('FeedItem unreact error', error);
    }
  });
  @action
  addUserReaction = flow(function* (
    this: FeedItem,
    userReaction: ReactionsApiTypes.UserReaction,
  ) {
    try {
      this.reactions.add(userReaction);
      this.react(userReaction.reaction!);
      yield;
    } catch (error) {
      ConsoleLogger.log('FeedItem.addUserReaction error', error);
    }
  });
  @action
  removeUserReaction = flow(function* (
    this: FeedItem,
    userReaction: ReactionsApiTypes.UserReaction,
  ) {
    try {
      this.reactions.remove(userReaction);
      this.unreact(userReaction.reaction!.reactionCode!);
      yield;
    } catch (error) {
      ConsoleLogger.log('FeedItem.removeUserReaction error', error);
    }
  });

  @action comment = flow(function* (
    this: FeedItem,
    comment: CommentsApiTypes.CommentEntity,
  ) {
    try {
      const response: {
        data: CommentsApiTypes.CreateCommentResponse;
      } = yield this.repository.comments.create({
        entityId: this.feedItemId,
        comment,
      });

      this.comments.add(response.data.comment!);
    } catch (error) {
      ConsoleLogger.log('FeedItem comment error: ', error);
    }
  });

  @action setEntity = (entity: IFeedItemEntity, updatedAt: Date) => {
    this.entity = entity;
    this.updatedAt = updatedAt;
  };

  @action setCommentsTotal(total: number) {
    this.comments.setTotal(total);
  }

  hasValidActivity(): boolean {
    return (
      this.activity &&
      Object.values(FeedItemActivityType).includes(this.activity.activityType)
    );
  }

  getContent() {
    const content =
      (this.entity && this.entity.body && this.entity.body.content) ||
      (this.activity && this.activity.data && this.activity.data.content);
    return content;
  }
}
